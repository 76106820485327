import './post.css';
import { fabric } from 'fabric';
import Like from '../like/Like'; // adjust the path as needed
import MyFriendsToSharePost from '../../components/myFriendsToSharePost/MyFriendsToSharePost';
import Button from 'react-bootstrap/Button';
import { MoreVert } from '@material-ui/icons';
import { IconButton, MenuItem, Menu } from '@material-ui/core';
import { useContext, useEffect, useState, useRef } from 'react';
//import { Button, Comment, Form, Header } from 'semantic-ui-react';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import axiosConn from '../../axiosConn';
import { CircularProgress } from '@material-ui/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DynamicHtml from '../dynamicHtml/DynamicHtml';
import Comment from '../comment/Comment';
import CreateComment from '../createComment/CreateComment';
import { format } from 'timeago.js';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import ReactPlayer from 'react-player/lazy';
import { useMediaQuery } from 'react-responsive';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import { Tooltip } from '@mui/material';
import useClickOutside from '../../clickOutside';
import { ClipLoader } from 'react-spinners';
import Modal from '../modal/Modal';
import checkHtmlDialogElement from '../../utils/checkHtmlDialogElement';
import playSound from '../../utils/playSound';
import { SocketContext } from '../../context/socket';
import { ViewPortContext } from '../../context/ViewPortContext';
import Form from 'react-bootstrap/Form';
import isVideoFile from '../../utils/isVideoFile';
import preciseFormat from '../../utils/preciseFormat';
import ModalImage from 'react-modal-image';

const winningSound =
  'https://res.cloudinary.com/joegalaxy/video/upload/v1713899521/y2mate_HrgsElu_gxito3.mp3';

export default function Post({
  galaxy = null,
  initialPost,
  onDelete,
  // handlePostClick
}) {
  // console.log(initialPost);
  const [post, setPost] = useState(initialPost);
  const { player: loggedInPlayer } = useContext(AuthContext);
  const checkbox = useRef();

  const [like, setLike] = useState(post?.likes?.length || 0);
  const [share, setShare] = useState(
    (post?.rePosts?.length || 0) + (post?.reShares?.length || 0),
  );
  const [jgkd, setJgkd] = useState(post?.jgkd?.length || 0);
  // const [isJgkd, setIsJgkd] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  // const [isShared, setIsShared] = useState(false);

  const [views, setViews] = useState(post?.views);

  const [player, setPlayer] = useState({});
  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const { player: currentPlayer } = useContext(AuthContext);
  const [isVideo, setIsVideo] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isShown, setIsShown] = useState(false);
  //const [isCloud, setIsCloud] = useState(false);
  const [commentCount, setCommentCount] = useState(1);
  const [comments, setComments] = useState(post?.comments || []);
  const [isQAutoUrl, SetIsQAutoUrl] = useState(post?.img?.includes('q_auto'));
  const [isProgressiveUrl, SetIsProgressiveUrl] = useState(
    post?.img?.includes('fl_progressive:steep'),
  );
  const [qAutoUrl, SetQAutoUrl] = useState('');
  const [likePlayer, setLikePlayer] = useState({});
  const [likeProfileImgs, setlikeProfileImgs] = useState([]);
  const [messageTextIsVideo, setMessageTextIsVideo] = useState(false);
  const [jgkOnPost, setJGKOnPost] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sendJGKToPostAmount, setSendJGKToPostAmount] = useState(0);
  const [myFriends, SetMyFriends] = useState([]);
  const [myFriendsToSharePost, SetMyFriendsToSharePost] = useState([]);

  const socket2 = useContext(SocketContext);
  const [onlinePlayers, setOnlinePlayers] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [loading, setLoading] = useState(false);

  let subtitle;
  const [modal, setModal] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [isRepost, setIsRepost] = useState(false);
  const [dialogIsSupported, setDialogIsSupported] = useState(false);
  const imgRef = useRef(null);
  const [textPosition, setTextPosition] = useState({ top: '0px', left: '0px' });
  const [isSoundPlaying, setIsSoundPlaying] = useState(false);

  const [showText, setShowText] = useState(false);

  //const MyOptions = ['Delete Post', 'Edit Post'];
  const MyOptions = ['Delete Post'];
  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });
  const { width } = useContext(ViewPortContext);

  const menu = useRef(null);
  const [isShownMenuItem, setIsShownMenuItem] = useState(false);

  useClickOutside(menu, () => setIsShownMenuItem(false));

  const handleClick = (event) => {
    // console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
    setIsShownMenuItem(true);
  };

  const open = Boolean(anchorEl);

  const handleClose = async () => {
    setAnchorEl(null);
    // console.log(post?.desc);
    // console.log(currentPlayer);
    // Emit the deletePost event to the server
    socket2.emit('deletePost', { post: initialPost });

    try {
      //const res =
      await axiosConn.delete(`/posts/${post._id}`, {
        headers: {
          Authorization: `Bearer ${currentPlayer.token}`,
        },
        data: {
          playerId: currentPlayer.data.player.playerId,
        },
      });
      onDelete(initialPost._id);
      //console.log(res.data);
    } catch (err) {
      console.log(err);
    }
    // window.location.reload();
  };

  useEffect(() => {
    //Post component checks if img or video .mp4
    if (!post || typeof post !== 'object') {
      return null;
    } //console.log(post.img);
    if (post.img) {
      const length = post.img.split('.')?.length;
      // if (
      //   post.img.split('.')[length - 1].slice(-3) === 'mp4' ||
      //   post.img.split('.')[length - 1].slice(-3) === 'mov'
      // ) {
      //   setIsVideo(true);
      // }
      const isVideo = isVideoFile(post?.img);
      setIsVideo(isVideo);
      // this will make the url q_auto if not and q_auto + progressive
      // aka a_auto full both.
      // lastly when both it will setIsQAutoUrl.
      if (!isQAutoUrl) {
        //if not already a q_auto url. add q_auto/fl_progressive:steep/
        SetQAutoUrl(
          post.img.split('/upload/')[0] +
            '/upload/q_auto/fl_progressive:steep/' +
            post.img.split('/upload/')[1],
        );
      } else if (!isProgressiveUrl) {
        // is already a q_auto but not progressive.
        // make it progressive too.
        SetQAutoUrl(
          post.img.split('/q_auto/')[0] +
            '/q_auto/fl_progressive:steep/' +
            post.img.split('/q_auto/')[1],
        );
        SetIsQAutoUrl(qAutoUrl.includes('q_auto/fl_progressive:steep'));
      }
    }
    setIsLiked(post?.likes?.includes(currentPlayer.data.player.playerId));
    return () => {};
  }, [currentPlayer.data.player.playerId, post?.img, post?.likes]);

  // console.log(qAutoUrl);
  useEffect(() => {
    let mounted = true;
    if (!post || typeof post !== 'object') {
      return null;
    }
    const fetchPlayer = async () => {
      try {
        // This query string is acceptable..
        const res = await axiosConn.get(`/players?playerId=${post?.playerId}`, {
          headers: {
            Authorization: `Bearer ${currentPlayer.token}`,
          },
        });
        // console.log(res.data.data.data[0]);

        if (!mounted) return;
        setPlayer(res.data.data.data[0]);
        // console.log(player.profilePhoto);
      } catch (error) {
        console.error('Error fetching player:', error);
        // Optionally, you can set an error state here to display an error message to the user
      }
    };
    fetchPlayer();

    return () => {
      mounted = false;
    };
  }, [post?.playerId, currentPlayer.token]);

  useEffect(() => {
    let mounted = true;
    //This grabs logged on player's friends.
    const getMyFriends = async () => {
      try {
        const friendList = await axiosConn.get(
          '/players/friends/' + loggedInPlayer.data.player._id,
          {
            headers: {
              Authorization: `Bearer ${loggedInPlayer.token}`,
            },
          },
        );
        if (!mounted) return;
        SetMyFriends(friendList.data);
        //  console.log(friendList.data);
      } catch (err) {
        console.log(err);
      }
    };
    getMyFriends();
    return () => {
      mounted = false;
    };
  }, [loggedInPlayer.token, loggedInPlayer.data.player._id]);

  useEffect(() => {
    setPost(initialPost);
    setLike(initialPost?.likes?.length || 0);
    setComments(initialPost?.comments || []);
    setCommentCount(initialPost?.comments?.length || 0);
    setCommentCount(2); // Reset to initial number of comments to show
  }, [initialPost]);

  const likeHandler = async () => {
    try {
      const response = await axiosConn.put(
        '/posts/' + post._id + '/like',
        {
          playerId: currentPlayer.data.player.playerId,
          profilePhoto: currentPlayer.data.player.profilePhoto,
        },
        {
          headers: {
            Authorization: `Bearer ${currentPlayer.token}`,
          },
        },
      );

      setLike(isLiked ? like - 1 : like + 1);
      setIsLiked(!isLiked);

      // console.log(response.data.data.post);
      // updatePost(response.data.data.post);

      // Emit the updated post to the server
      socket2.emit('updatePost', { updatedPost: response.data.data.post });
    } catch (err) {
      console.error(err);
    }
  };

  const rePostHandler = () => {
    //console.log(currentPlayer);
    // console.log('rePostHander Top');
    try {
      axiosConn.put(
        '/posts/' + post._id + '/repost',
        {
          playerId: currentPlayer.data.player.playerId,
        },
        {
          headers: {
            Authorization: `Bearer ${currentPlayer.token}`,
          },
        },
      );
    } catch (err) {}
    setShare((prev) => prev + 1);
    // setIsLiked(!isLiked);
  };
  const shareHandler = async () => {
    //console.log(currentPlayer);
    // console.log('shareHander Top');
    try {
      const res = await axiosConn.put(
        '/posts/' + post._id + '/reshare',
        {
          playerId: currentPlayer.data.player.playerId,
        },
        {
          headers: {
            Authorization: `Bearer ${currentPlayer.token}`,
          },
        },
      );
      // console.log(res.data);
      if (!res.data.includes('The post has already been reShared')) {
        setShare((prev) => prev + 1);
      }
    } catch (err) {}
    // setIsLiked(!isLiked);
  };

  const showMore = () => {
    setCommentCount((prev) => prev + 3);
  };

  useEffect(() => {
    if (!post || typeof post !== 'object') {
      return null;
    }
    //setting views
    try {
      axiosConn.put(
        '/posts/' + post._id + '/view',
        {},
        {
          headers: {
            Authorization: `Bearer ${currentPlayer.token}`,
          },
        },
      );
    } catch (err) {
      console.log(err);
    }
    //console.log(post);
    setViews((view) => view + 1);
    return () => {};
  }, [currentPlayer.token, post?._id]);

  // console.log(post);
  const handleClickJGKOnPostToggle = (event) => {
    // console.log('handleClickJGKOnPostToggle', jgkOnPost);
    jgkOnPost === false ? setJGKOnPost(true) : setJGKOnPost(false);
  };

  const handleSendJGKToPostAmount = async (e) => {
    // console.log('handleSendJGKToPostAmount');
    if (isSoundPlaying) return; // Prevent function from running if sound is playing

    // console.log(e.target.value);
    if (e.key === 'Enter' || e.type === 'click') {
      //send JGK.
      setIsLoading(true);

      // console.log(myFriends);
      let receiver;
      if (post.galaxyId) {
        receiver = post.playerId;
      } else {
        const postPlayerId = myFriends.filter(
          (f) => post?.playerId === f.playerId,
        );
        if (postPlayerId.length === 0) {
          // Handle the case where post.playerId is not in myFriends
          setIsLoading(false);
          return;
        }
        receiver = postPlayerId[0]._id;
      }
      // console.log(
      //   postPlayerId[0]._id,
      //   loggedInPlayer.data.player._id,
      //   sendJGKToPostAmount,
      // );

      if (sendJGKToPostAmount > loggedInPlayer.data.player.jgkTokens) {
        //setError
        return;
      }

      try {
        // console.log('sender: ', loggedInPlayer.data.player._id);
        // console.log('receiver: ', postPlayerId[0]._id);
        // console.log('receivingPostId: ', post._id);
        // console.log(`text: POGW:${post?.playerId}:${post?.desc}: ${post?._id}`);
        // console.log(receiver);
        const res = await axiosConn.post(
          `/jgkTokenTransactions/sendJGKToPostPOGW/`,
          {
            receiver: receiver,
            receivingPostId: post._id,
            sender: loggedInPlayer.data.player._id,
            amount: sendJGKToPostAmount,
            text: `POGW:${post?.playerId}:${post?.desc}: ${post?._id}`,
          },
          {
            headers: {
              Authorization: `Bearer ${loggedInPlayer.token}`,
            },
          },
        );
        // window.location.href = res2.data.session.url;
        // console.log(res.data.post);
        // playSound
        setIsLoading(false);
        setJGKOnPost(false);
        setIsSoundPlaying(true); // Indicate that sound is about to play
        await playSound(winningSound);
        setIsSoundPlaying(false); // Reset after sound has played

        setPost(res.data.post);

        // Emit the updated post to the server
        socket2.emit('updatePost', { updatedPost: res.data.post });
      } catch (err) {
        setIsLoading(false);

        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (modal) {
      // console.log('modal');
      socket2.on('getPlayers', (players) => {
        setOnlinePlayers(
          loggedInPlayer.data.player.friends.filter((f) =>
            players.some((u) => u.playerId === f),
          ),
        );
      });
    }
    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      socket2.off('getPlayers');
    };
  }, [loggedInPlayer.data.player.friends, modal, socket2]);

  const sharePostToMessenger = async (receiverPlayer, senderPlayer) => {
    // console.log(receiverPlayer, senderPlayer);
    if (receiverPlayer?._id && senderPlayer?._id) {
      try {
        const res = await axiosConn
          .get(
            `/conversations/find/${receiverPlayer._id}/${senderPlayer._id}`,
            {
              headers: {
                Authorization: `Bearer ${loggedInPlayer.token}`,
              },
            },
          )
          .then(async (res) => {
            if (res.data === null) {
              // console.log('No conversation yet. creating...');
              try {
                //create new conversation.
                const res = await axiosConn.post(
                  `/conversations/`,
                  {
                    senderId: loggedInPlayer.data.player._id,
                    receiverId: receiverPlayer._id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${loggedInPlayer.token}`,
                    },
                  },
                );

                // console.log(res.data);
                setCurrentChat(res.data);
                // console.log(
                //   'sending...to new conversation..conversationId: ',
                //   res.data._id,
                // );
                let message = {
                  sender: loggedInPlayer.data.player._id,
                  text: `sharedPost by ${post.desc}\nhttps://www.joegalaxy.net/post/${post._id} <-- click to goto sharedPost.`,
                  img: post.img,
                  conversationId: res.data._id,
                  // isRead: false,
                  isRead: onlinePlayers?.includes(receiverPlayer._id)
                    ? true
                    : false,
                };
                // console.log(message);
                //send to socket
                try {
                  socket2.emit('sendMessage', {
                    senderId: loggedInPlayer.data.player._id,
                    senderPlayerId: loggedInPlayer.data.player.playerId,
                    receiverId: receiverPlayer._id,
                    text: `sharedPost by ${post.desc}\nhttps://www.joegalaxy.net/post/${post._id} <-- click to goto sharedPost.`,
                    img: post.img,
                  });
                  // checkPageStatus(text, player.data.player._id);
                } catch (err) {
                  console.log(err);
                }

                // console.log(onlinePlayers.includes(receiverId) ? 'true' : 'false');
                try {
                  setLoading(true);
                  //send to messages table
                  const res = await axiosConn.post(
                    `/messages/${
                      onlinePlayers.includes(receiverPlayer._id)
                        ? 'true'
                        : 'false'
                    }`,
                    message,
                    {
                      headers: {
                        Authorization: `Bearer ${loggedInPlayer.token}`,
                      },
                    },
                  );
                  // console.log(res.data.data.data);
                  // console.log(...messages);
                  setLoading(false);
                } catch (err) {
                  setLoading(false);
                  console.log(err);
                }
              } catch (err) {
                console.log(err);
              }
            } else {
              //existing conversation.
              // console.log(
              //   'sending to existing conversation...conversationId: ',
              //   res.data._id,
              // );
              let message = {
                sender: loggedInPlayer.data.player._id,
                text: `sharedPost by ${post.playerId}: ${post.desc}\nhttps://www.joegalaxy.net/post/${post._id} <-- click to goto sharedPost.`,
                img: post.img,
                conversationId: res.data._id,
                // isRead: false,
                isRead: onlinePlayers?.includes(receiverPlayer._id)
                  ? true
                  : false,
              };
              // console.log(message);
              //send to sockedt
              try {
                socket2.emit('sendMessage', {
                  senderId: loggedInPlayer.data.player._id,
                  senderPlayerId: loggedInPlayer.data.player.playerId,
                  receiverId: receiverPlayer._id,
                  text: `sharedPost by ${post.playerId}: ${post.desc}\nhttps://www.joegalaxy.net/post/${post._id} <-- click to goto sharedPost.`,
                  img: post.img,
                });
                // checkPageStatus(text, player.data.player._id);
              } catch (err) {
                console.log(err);
              }

              // console.log(onlinePlayers.includes(receiverId) ? 'true' : 'false');
              try {
                setLoading(true);
                //send to messages
                const res = await axiosConn.post(
                  `/messages/${
                    onlinePlayers.includes(receiverPlayer._id)
                      ? 'true'
                      : 'false'
                  }`,
                  message,
                  {
                    headers: {
                      Authorization: `Bearer ${loggedInPlayer.token}`,
                    },
                  },
                );
                // console.log(res.data.data.data);
                // console.log(...messages);
                setLoading(false);
              } catch (err) {
                setLoading(false);
                console.log(err);
              }
            }
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    setDialogIsSupported(checkHtmlDialogElement());
  }, []);

  const handleSharePost = () => {
    setIsLoading(true);

    // console.log('handleSharePost Top');
    // console.log(myFriendsToSharePost);
    myFriendsToSharePost.forEach((friend) => {
      sharePostToMessenger(friend, loggedInPlayer.data.player);
      // sharePostToMessenger(post, friend);
    });
    shareHandler(); //add playerId to reShares on Post.

    setIsLoading(false);
    setModal(false);
  };

  const handleRepost = () => {
    setIsLoading(true);
    // console.log('handleRepost');
    rePostHandler();
    setModal(false);

    setIsLoading(false);
    window.location.reload();
  };

  const handleIsShareClicked = () => {
    // console.log('handleIsShareClicked');
    setIsShare((prev) => !prev);
  };

  const handleIsRepostClicked = () => {
    // console.log('handleIsRepost');
    setIsRepost((prev) => !prev);
  };

  // if (!post || typeof post !== 'object') {
  //   return null;
  // }
  // console.log(post);
  return (
    <>
      <Tooltip
        title={
          post?.isPogw ? `Proof Of Great Work, consider rewarding JGK` : null
        }
      >
        <div
          className={post?.isPogw ? 'postPOGW' : 'post'}
          // onClick={handlePostClick}
        >
          <div className="postWrapper">
            <div className="postTop">
              <div className="postTopLeft">
                <Link to={`/profile/${player?.playerId}`}>
                  <img
                    className={
                      !mobileView ? 'postProfileImg' : 'postProfileImgMobile'
                    }
                    src={
                      player.profilePhoto
                        ? player.profilePhoto
                        : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                    }
                    alt=""
                    loading="lazy"
                  />
                </Link>
                <span
                  className={
                    !mobileView ? 'postPlayerId' : 'postPlayerIdMobile'
                  }
                >
                  {player.playerId}
                </span>
                <div className={!mobileView ? 'postViews' : 'postViewsMobile'}>
                  <VisibilityIcon sx={{ color: 'grey', fontSize: 15 }} />
                  {views}
                </div>
                <span className={!mobileView ? 'postDate' : 'postDateMobile'}>
                  {preciseFormat(post?.createdAt)}
                </span>
                {post?.isPogw ? (
                  !mobileView ? (
                    <span className="pogwTitleSpan">Proof of Great Work</span>
                  ) : (
                    <span className="pogwTitleSpanMobile">POGW</span>
                  )
                ) : null}
              </div>
              <div
                className={
                  mobileView && post?.isPogw
                    ? 'postTopRightPOGW'
                    : 'postTopRight'
                }
              >
                {(player.playerId === currentPlayer.data.player.playerId ||
                  (galaxy &&
                    (galaxy.owners || []).some(
                      (owner) => owner._id === currentPlayer.data.player._id,
                    )) ||
                  (galaxy &&
                    (galaxy.administrators || []).some(
                      (admin) => admin._id === currentPlayer.data.player._id,
                    )) ||
                  (galaxy &&
                    (galaxy.moderators || []).some(
                      (moderator) =>
                        moderator._id === currentPlayer.data.player._id,
                    ))) && (
                  <IconButton
                    aria-label="more"
                    onClick={handleClick}
                    aria-haspopup="true"
                    aria-controls="long-menu"
                    onMouseEnter={() => setIsShown(true)}
                    onMouseLeave={() => setIsShown(false)}
                  >
                    {isShown ? (
                      <div
                        className={
                          !mobileView
                            ? 'postDeletePostText'
                            : 'postDeletePostMobile'
                        }
                      >
                        Delete Post
                      </div>
                    ) : (
                      <MoreVert
                        fontSize={
                          !mobileView || width === 800 ? 'large' : 'small'
                        }
                      />
                    )}
                  </IconButton>
                )}
                {isShownMenuItem &&
                  player.playerId === currentPlayer.data.player.playerId && (
                    <Menu anchorEl={anchorEl} keepMounted open={open}>
                      {MyOptions.map((option) => (
                        <MenuItem key={option} onClick={handleClose} ref={menu}>
                          {option}
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
              </div>
            </div>
            {post?.isPogw && (
              <div>
                <Tooltip
                  title={`This post has ${(
                    Math.round(parseFloat(post?.jgkTokens) * 100) / 100
                  ).toFixed(2)} JGK`}
                >
                  <span
                    id="postJGKAmount"
                    className="postJGKAmount"
                    // className={
                    //   post?.jgkTokens < 9999
                    //     ? 'postNotificationIconBadgeJGK'
                    //     : 'postNotificationIconBadgeJGK10K'
                    // }
                  >
                    {post?.jgkTokens &&
                      (
                        Math.round(parseFloat(post?.jgkTokens) * 100) / 100
                      ).toFixed(2)}
                  </span>
                </Tooltip>
                <span className="postJGKBadge">JGK</span>
              </div>
            )}
            <div className="postCenter" ref={imgRef}>
              {/* {post?.rePosts?.length > 0 && (
                <span className="postText"> */}
              {/* rePosted */}
              {/* {post?.rePosts?.filter(
                    (p) => p.playerId !== currentPlayer.data.player.playerId,
                  )} */}
              {/* </span> */}
              {/* )} */}
              <DynamicHtml
                from="post"
                text={post?.desc}
                setMessageTextIsVideo={setMessageTextIsVideo}
              />
              {isVideo ? (
                <ReactPlayer
                  autoPlay={true}
                  loop={true}
                  muted={true}
                  playsinline={true}
                  width="100%"
                  height="75%"
                  config={{
                    file: {
                      attributes: {
                        onContextMenu: (e) => e.preventDefault(),
                        playsInline: true,
                      },
                    },
                  }}
                  playing={true}
                  controls={true}
                  url={isQAutoUrl ? post?.img : qAutoUrl}
                />
              ) : post?.img ? (
                <ModalImage
                  hideDownload={true}
                  small={isQAutoUrl ? post?.img : qAutoUrl}
                  large={isQAutoUrl ? post?.img : qAutoUrl}
                  className="postImg"
                  //src={post.img}
                  src={isQAutoUrl ? post?.img : qAutoUrl}
                  //src={qAutoUrl}
                  alt=""
                  // loading="lazy"
                  // style={{
                  //   backgroundImage: `url("https://res.cloudinary.com/joegalaxy/image/upload/q_auto/fl_progressive:steep/v1672717524/loading-gif_sfv2dl.gif")`,
                  //   backgroundPosition: 'center',
                  //   backgroundSize: '30%',
                  //   backgroundRepeat: 'no-repeat',
                  // }}
                />
              ) : null}
            </div>
            {post?.isPogw && (
              <>
                <div className="sendJGKToPostPOGWWrapper">
                  <div
                    className={jgkOnPost ? 'pogwIconIconClicked' : 'pogwIcon'}
                  >
                    <Tooltip
                      title={
                        jgkOnPost
                          ? 'click to close JGK on this POGW.'
                          : `click to give JGK to ${post?.playerId} for this Proof of Great Work.`
                      }
                    >
                      <CurrencyExchangeOutlinedIcon
                        onClick={
                          post?.playerId !== loggedInPlayer.data.player.playerId
                            ? handleClickJGKOnPostToggle
                            : null
                        }
                        fontSize={!mobileView ? 'medium' : 'small'}
                      />
                    </Tooltip>
                  </div>
                  {!jgkOnPost && (
                    <>
                      <span
                        className={
                          !mobileView
                            ? 'postJgkdCounter'
                            : 'postJgkdCounterMobile'
                        }
                      >
                        {jgkd > 0 ? jgkd : null}
                        {jgkd === 1
                          ? ' jgk'
                          : jgkd > 1
                          ? ' jgks '
                          : 'be 1st to jgk '}
                      </span>
                      <div className="postLikeProfileImgs">
                        {jgkd > 0 &&
                          post?.jgkdProfileImgs &&
                          post?.jgkdProfileImgs
                            .slice(0, width < 360 ? 4 : 6)
                            .map((jgkdProfileImg, i) => (
                              <Link
                                to={`/profile/${jgkdProfileImg.playerId}`}
                                key={i}
                              >
                                <img
                                  src={
                                    jgkdProfileImg.profilePhoto
                                      ? jgkdProfileImg.profilePhoto
                                      : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                                  }
                                  alt=""
                                  loading="lazy"
                                  style={{
                                    transform: `translateX(${-i * 8}px)`,
                                    zIndex: `${i}`,
                                  }}
                                />
                              </Link>
                            ))}
                      </div>
                    </>
                  )}
                  {jgkOnPost && (
                    <>
                      <div className="postJGKInputSend">
                        <div className="sendJGKToPostAmountInputs">
                          <label
                            className="sendJGKToPostAmountLabel"
                            for="sendJGKToPlayerAmount"
                          >
                            Amount:
                          </label>
                          <input
                            type="number"
                            value={sendJGKToPostAmount}
                            id="sendJGKToPlayerAmount"
                            onChange={(e) =>
                              setSendJGKToPostAmount(e.target.value)
                            }
                            onKeyUp={handleSendJGKToPostAmount}
                          ></input>
                        </div>
                        <div className="sendJGKToPostPOGWButton">
                          <button
                            className={
                              !jgkOnPost || sendJGKToPostAmount < 1
                                ? 'sendJGKPostSubmitButtonDisabled'
                                : 'sendJGKPostSubmitButton'
                            }
                            value="Send"
                            disabled={sendJGKToPostAmount < 1 ? true : false}
                            onClick={handleSendJGKToPostAmount}
                          >
                            {isLoading ? (
                              <CircularProgress color="inherit" size="20px" />
                            ) : (
                              'Send'
                            )}
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            <div
              className={
                post?.isPogw
                  ? post?.jgkd.includes(loggedInPlayer.data.player.playerId) ||
                    post?.playerId === loggedInPlayer.data.player.playerId
                    ? 'postBottom'
                    : 'postBottom myDiv'
                  : 'postBottom'
              }
            >
              <Like
                imgRef={imgRef}
                text="+2 JGK, Yay!"
                likes={like}
                likesProfileImgs={post?.likesProfileImgs}
                isLiked={isLiked}
                likeHandler={likeHandler}
                mobileView={mobileView}
                PF={PF}
                title={isLiked ? 'You liked this' : ''}
                textOffset={post?.isPogw ? -13 : -20}
              />
            </div>
            {dialogIsSupported && (
              <div className="sharePostModal" title="Share Post">
                <div className="shareCountDiv">
                  <div className="sendOutlinedIcon">
                    <SendOutlinedIcon
                      onClick={() => {
                        setModal(true);
                      }}
                    />
                  </div>
                  <span className="postRePostCounter">
                    {share > 0 ? share : null}
                  </span>
                  <span
                    className={
                      !mobileView ? 'postLikeCounter' : 'postLikeCounterMobile'
                    }
                  >
                    {width > 280 &&
                      (share === 1
                        ? ' share'
                        : share > 1
                        ? ' shares '
                        : 'be 1st to share ')}
                  </span>
                </div>

                <Modal openModal={modal}>
                  <div className="modalContent">
                    <div className="modalHeaders">
                      {!isRepost && (
                        <div className="shareDiv">
                          <Form>
                            {['checkbox'].map((type) => (
                              <div key={`default-${type}`} className="mb-3">
                                <Form.Check
                                  ref={checkbox}
                                  type={type}
                                  id={`${player._id}`}
                                  // label={` Share`}
                                  onClick={(e) => {
                                    handleIsShareClicked();
                                  }}
                                />
                              </div>
                            ))}
                          </Form>
                          <h2 className="modalHeader">Share</h2>
                        </div>
                      )}

                      {!isShare &&
                        loggedInPlayer.data.player.playerId !==
                          post?.playerId && (
                          <div className="repostDiv">
                            <Form>
                              {['checkbox'].map((type) => (
                                <div key={`default-${type}`} className="mb-3">
                                  <Form.Check
                                    ref={checkbox}
                                    type={type}
                                    id={`${player._id}`}
                                    // label={` Share`}
                                    onClick={(e) => handleIsRepostClicked()}
                                  />
                                </div>
                              ))}
                            </Form>
                            <h2 className="modalHeader">Repost</h2>
                          </div>
                        )}
                    </div>
                    {!isRepost && (
                      <>
                        <div>To: </div>
                        <div className="postRequestPlayersSearchResults">
                          {
                            <MyFriendsToSharePost
                              searchedPlayers={myFriends}
                              myFriendsToSharePost={myFriendsToSharePost}
                              SetMyFriendsToSharePost={SetMyFriendsToSharePost}
                            />
                          }
                        </div>
                      </>
                    )}

                    <div className="postShareCloseSend topright">
                      <button
                        className="sharePostSubmitButton"
                        onClick={() => {
                          setModal(false);
                        }}
                      >
                        x
                      </button>
                    </div>

                    <div className="postShareCloseSend">
                      <button
                        className={
                          (myFriendsToSharePost &&
                            myFriendsToSharePost.length > 0) ||
                          isRepost
                            ? 'sharePostSubmitButton'
                            : 'sharePostSubmitButtonDisabled'
                        }
                        onClick={isShare ? handleSharePost : handleRepost}
                        disabled={
                          (myFriendsToSharePost &&
                            myFriendsToSharePost.length > 0) ||
                          isRepost
                            ? false
                            : true
                        }
                      >
                        {loading ? (
                          <CircularProgress color="inherit" size="20px" />
                        ) : isShare ? (
                          'Send'
                        ) : (
                          'Go'
                        )}
                      </button>
                    </div>
                  </div>
                </Modal>
              </div>
            )}
            <div
              className={
                post?.isPogw
                  ? post?.jgkd.includes(loggedInPlayer.data.player.playerId) ||
                    post?.playerId === loggedInPlayer.data.player.playerId
                    ? 'commentBottom'
                    : 'commentBottom myDiv'
                  : 'commentBottom'
              }
            >
              <div className="comments_wrap">
                <CreateComment
                  postId={post?._id}
                  setComments={setComments}
                  setCommentCount={setCommentCount}
                  parent="Post"
                  socket2={socket2}
                />

                <span className="postCommentText">
                  {comments &&
                    comments
                      .sort((a, b) => {
                        return new Date(b.commentAt) - new Date(a.commentAt);
                      })
                      .slice(0, commentCount)

                      .map((comment, i) => (
                        <Comment
                          comment={comment}
                          key={comment._id}
                          postId={post?._id}
                          imgRef={imgRef}
                        />
                      ))}
                  {commentCount < (comments ? comments?.length : 0) && (
                    <div
                      className={
                        !mobileView ? 'viewComments' : 'viewCommentsMobile'
                      }
                      onClick={() => showMore()}
                    >
                      View {comments?.length - commentCount} more{' '}
                      {comments?.length - commentCount === 1
                        ? 'comment'
                        : 'comments'}
                    </div>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Tooltip>
    </>
  );
}
